
import CompTable from "@/components/comp-table.vue"
import CompModal from "@/components/comp-modal.vue"
import ElemForm from "@/components/elem-form.vue"

import Request from "@/module/request/request"
import Message from "@/module/interactive/message"

export default {
    components: {
        CompTable,
        CompModal,
        ElemForm,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            columns: [
                {
                    title: "收入类型",
                    render: (h, params) => {
                        return { OTHER_INCOME: "其他收入", RECHARGE_INCOME: "充值收入" }[params.row.incExpType] || "其他"
                    },
                },
                {
                    title: "收入金额",
                    render: (h, params) => {
                        return "￥" + (!params.row.amount ? "0.00" : (params.row.amount / 100).toFixed(2))
                    },
                },
                {
                    title: "描述",
                    key: "description",
                },
                {
                    title: "创建日期",
                    key: "createdDate",
                },
            ],

            structure: [
                {
                    field: "amount",
                    name: "amount",
                    title: "收入金额",
                    type: "Price",
                    required: true,
                },
                {
                    field: "description",
                    name: "description",
                    title: "收入描述",
                    type: "Input",
                    required: false,
                },
            ],
        }
    },

    methods: {
        async onSubmit() {
            const data = await this.$refs.form.getFormData()

            Request.post("WEB://IncomeExpense/Save", {
                ...data,
                incExpType: "OTHER_INCOME",
            }).then(() => {
                Message.success("新增成功")
                // 关闭弹窗
                this.$refs.form_modal.close()
                // 刷新表格
                this.$refs.comp_table.refresh()
            })
        },

        onDisplayForm() {
            this.$refs.form_modal.display()
        },
    },
}
