import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "income-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Option = _resolveComponent("Option")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_comp_table = _resolveComponent("comp-table")!
  const _component_elem_form = _resolveComponent("elem-form")!
  const _component_comp_modal = _resolveComponent("comp-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_comp_table, {
      ref: "comp_table",
      title: "收入明细",
      entity: "BusinessOrder",
      columns: $data.columns,
      "table-api": "WEB://IncomeExpense/FindAllToPage?type=INCOME"
    }, {
      search: _withCtx((evt) => [
        _createVNode(_component_Select, {
          modelValue: evt.search.type,
          "onUpdate:modelValue": ($event: any) => ((evt.search.type) = $event),
          placeholder: "收款类型",
          style: {"width":"200px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([
                            { name: '自定收款', value: 'CHARGE' },
                            { name: '车辆服务费', value: 'VEHICLE' },
                        ], (item) => {
              return _createVNode(_component_Option, {
                value: item.value,
                key: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            }), 64))
          ]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"]),
        _createVNode(_component_Input, {
          modelValue: evt.search.name,
          "onUpdate:modelValue": ($event: any) => ((evt.search.name) = $event),
          placeholder: "名称"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ]),
      operate: _withCtx(() => [
        _createVNode(_component_Button, {
          type: "info",
          onClick: $options.onDisplayForm
        }, {
          default: _withCtx(() => [
            _createTextVNode("新增一笔收入")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      "row-operate": _withCtx((params) => [
        _createVNode(_component_Button, {
          type: "info",
          style: {"margin":"3px"},
          onClick: ($event: any) => (_ctx.$router.push('/meal/form?id=' + params.row.uuid))
        }, {
          default: _withCtx(() => [
            _createTextVNode("编辑")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_Button, {
          type: "error",
          style: {"margin":"3px"},
          onClick: ($event: any) => (params.event.delete({ uuid: params.row.uuid }))
        }, {
          default: _withCtx(() => [
            _createTextVNode("删除")
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["columns"]),
    _createVNode(_component_comp_modal, {
      title: "新增收入",
      ref: "form_modal",
      height: "auto",
      "on-success": $options.onSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_elem_form, {
          ref: "form",
          structure: $data.structure
        }, null, 8, ["structure"])
      ]),
      _: 1
    }, 8, ["on-success"])
  ]))
}